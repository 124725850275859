import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) {
    // this.userArray = Object.values(this.users);
  }

  getUsers(filterName, filterId, eventId) {
    return new Promise((resolve, reject) => {
      if (filterName && filterId) {
        this.http.get("https://api.whip.live/whipapi/app/admin/race-dashboard/?pwd=!whipadmin360!&to=1000&eventId="+eventId+"&" + filterName + "=" + filterId).subscribe((data: any) => {
          resolve(data);
        }, error => {
          reject(error);
        });
      } else {
        this.http.get('https://api.whip.live/whipapi/app/admin/race-dashboard/?pwd=!whipadmin360!&to=1000&eventId='+eventId).subscribe((data: any) => {
          resolve(data);
        }, error => {
          reject(error);
        });
      }
    });
  }

  getEventWaypoints(currentDay) {
    let waypoints = [
      {
        day: 6,
        ct: [
          {
            type: "bisonti",
            points: [
              [43.231041077524424, 13.355323104187846],
              [43.296931469812989, 12.873438438400626]
            ]
          }, {
            type: "lupi",
            points: [
              [43.29740010201931, 12.872922280803323],
              [43.231334611773491, 13.35533307865262]
            ]
          }
        ] 
      },
      {
        day: 0,
        ct: [
          {
            type: "bisonti",
            points: [
              [43.079568855464458, 13.274247134104371],
              [43.232454853132367, 13.355076173320413]
            ]
          }, {
            type: "lupi",
            points: [
              [43.079682849347591, 13.274231124669313],
              [43.231440307572484, 3.355271639302373]
            ]
          }
        ]
      }
    ];
    if (currentDay == 6) {
      return waypoints[0];
    } else {
      return waypoints[1];
    }
  }

  getUserPath(riderId, eventData) {
    let eventMeta = eventData.meta;
    return new Promise ((resolve, reject) => {
      this.http.get('https://api.whip.live/whipapi/app/admin/race-dashboard/user/' + riderId + '?pwd=!whipadmin360!&eventId='+eventData.id+'&fromTime='+eventMeta.from_time+'&toTime='+eventMeta.to_time).subscribe((data: any) => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  getEvent(eventId) {
    return new Promise((resolve, reject) => {
      this.http.get('https://api.whip.live/whipapi/app/v3/search/searchable/'+eventId).subscribe((data: any) => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  getEventCheckpoints(eventId) {
    return new Promise((resolve, reject) => {
      this.http.get('https://api.whip.live/whipapi/app/v4/events/checkpoints/'+eventId).subscribe((data: any) => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  getEventCheckpointPassages(eventId, checkPointId) {
    return new Promise((resolve, reject) => {
      this.http.get('https://api.whip.live/whipapi/app/v4/events/checkpoints/'+eventId+"/"+checkPointId).subscribe((data: any) => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

}
